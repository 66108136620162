import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";

// import IconCall from "../images/icons/theme/communication/call-1.svg";
// import IconMail from "../images/icons/theme/communication/send.svg";
//
// import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
// import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
// import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
// import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import IconCheck from "../images/icons/interface/check.svg";
import { MarketingManagerJsonLd } from "../components/jsonld/MarketingManagerJsonLd";

const MarketingmanagerPage = ({ data, location: { pathname } }) => (
  <Layout
    headerBgClass="bg-primary-alt"
    hideFooter
    footerBgClass="bg-white"
    meta={{ seo: data.datoCmsPage.seo, path: pathname }}
  >
    <MarketingManagerJsonLd />

    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">
                Marketing Specialist
                <br />
                Full-Time - 100% Remote
              </h1>
              <div className="lead">
                <p>
                  We’re looking for a flexible and versatile marketer who will
                  be responsible for the growth of our inbound channels. Marketing manager responsibilities include
                  tracking and analyzing the performance of advertising
                  campaigns, managing the marketing budget and ensuring that all
                  marketing material is in line with our brand identity.
                </p>
                <p>
                  To be successful in this role, you should have hands-on
                  experience with web analytics tools and be able to turn
                  creative ideas into effective advertising projects.
                  Ultimately, you will help us build and maintain a strong and
                  consistent brand through a wide range of online and offline
                  marketing channels.
                </p>
              </div>

              <p className="lead mb-2">
                <strong>Responsibilities:</strong>
              </p>
              <ul className="lead mb-5">
                <li>
                  Develop strategies and tactics to get the word out about our
                  company and drive qualified traffic to our front door
                </li>
                <li>
                  Deploy successful marketing campaigns and own their
                  implementation from ideation to execution
                </li>
                <li>
                  Experiment with a variety of organic and paid acquisition
                  channels like content creation, content curation, pay per
                  click campaigns, event management, publicity, social media,
                  lead generation campaigns, copywriting, performance analysis
                </li>
                <li>
                  Produce valuable and engaging content for our website and blog
                  that attracts and converts our target groups
                </li>
                <li>
                  Build strategic relationships and partner with key industry
                  players, agencies and vendors
                </li>
                <li>
                  Oversee and approve marketing material, from website banners
                  to hard copy brochures and case studies
                </li>
                <li>
                  Measure and report on the performance of marketing campaigns,
                  gain insight and assess against goals
                </li>
                <li>Optimize content considering SEO and Google Analytics</li>
                <li>
                  Share content through various channels, ensuring strong web
                  presence
                </li>
                <li>Deliver engaging content on a regular basis</li>
                <li>
                  Manage a portfolio of accounts to achieve long-term success
                </li>
                <li>Develop positive relationships with clients</li>
              </ul>

              <p className="lead mb-2">
                <strong>Requirements:</strong>
              </p>
              <ul className="lead mb-5">
                <li>
                  Proven experience in identifying target audiences and in
                  creatively devising and leading across channels marketing
                  campaigns that engage, educate and motivate
                </li>
                <li>
                  Solid knowledge of website analytics tools (e.g., Google
                  Analytics)
                </li>
                <li>
                  A sense of aesthetics and a love for great copy and witty
                  communication
                </li>
                <li>
                  Up-to-date with the latest trends and best practices in online
                  marketing and measurement
                </li>
              </ul>

              <p className="lead mb-2">
                <strong>Benefits:</strong>
              </p>
              <ul className="lead mb-5">
                <li>100% Remote</li>
                <li>No micromanagement</li>
                <li>Balanced workload</li>
                <li>B2B Contract</li>
              </ul>
            </div>

            <div className="col-12 col-md-8">
              <div className="card card--common card-body card--no-hover">
                <div id="activecampaign-form">
                  <div id="activecampaign-form__wrapper">
                    <div className=" _form_21"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=21")}
  </Layout>
);

export default MarketingmanagerPage;

export const query = graphql`
  query marketingmanagerPage {
    datoCmsPage(slug: { eq: "marketing-manager" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
