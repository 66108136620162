import React from "react";
import { Helmet } from "react-helmet";

export const MarketingManagerJsonLd = () => {
  const mainjson = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    datePosted: "2021-01-01",
    validThrough: "2021-12-30",
    baseSalary: {
      "@type": "MonetaryAmount",
      minValue: "$",
      maxValue: "$",
      currency: "USD"
    },
    description:
      "If you live and breathe marketing & sales strategy, we need to talk. We’re looking for a flexible and versatile marketer who will be responsible for the growth of our inbound and outbound sales channels. Marketing manager responsibilities include tracking and analyzing the performance of advertising campaigns, managing the marketing budget and ensuring that all marketing material is in line with our brand identity.",
    educationRequirements: ["no requirements"],
    employmentType: "Full-Time - 100% Remote",
    incentiveCompensation:
      "Performance-based annual bonus plan, project-completion bonuses",
    industry: "Computer Software",
    hiringOrganization: "Adam Fard Studio",
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Neue Schönhauser Straße 3",
        addressLocality: "Berlin",
        addressRegion: "BE",
        postalCode: "10178"
      }
    },
    applicationContact: "contact@adamfard.com",
    occupationalCategory: ["Marketing Specialist"],
    qualifications: [
      "Demonstrable experience in marketing and sales together with the potential and attitude required to learn",
      "Proven experience in identifying target audiences and in creatively devising and leading across channels marketing campaigns that engage, educate and motivate",
      "Solid knowledge of website analytics tools (e.g., Google Analytics)",
      "A sense of aesthetics and a love for great copy and witty communication",
      "Up-to-date with the latest trends and best practices in online marketing and measurement"
    ],
    responsibilities: [
      "Develop strategies and tactics to get the word out about our company and drive qualified traffic to our front door",
      "Deploy successful marketing campaigns and own their implementation from ideation to execution",
      "Experiment with a variety of organic and paid acquisition channels like content creation, content curation, pay per click campaigns, event management, publicity, social media, lead generation campaigns, copywriting, performance analysis",
      "Produce valuable and engaging content for our website and blog that attracts and converts our target groups",
      "Produce valuable and engaging content for our website and blog that attracts and converts our target groups",
      "Build strategic relationships and partner with key industry players, agencies and vendors",
      "Oversee and approve marketing material, from website banners to hard copy brochures and case studies",
      "Measure and report on the performance of marketing campaigns, gain insight and assess against goals",
      "Optimize content considering SEO and Google Analytics",
      "Share content through various channels, ensuring strong web presence, Deliver engaging content on a regular basis",
      "Manage a portfolio of accounts to achieve long-term success",
      "Develop positive relationships with clients",
      "Suggest actions to improve sales performance and identify opportunities for growth"
    ],
    jobBenefits: [
      "100% Remote",
      "No micromanagement",
      "Balanced workload",
      "B2B Contract"
    ],
    salaryCurrency: "USD",
    skills: "not required",
    specialCommitments: ["English C2", "English C1"],
    title: "Join Our Team!",
    url: "https://adamfard.com/marketing-manager",
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp"
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/marketing-manager/#Webpage",
    url: "https://adamfard.com/marketing-manager",
    headline: "Marketing Specialist",
    primaryImageOfPage: [
      "https://adamfard.com/static/2742500823d429dbc7a8106e6664e1af/58556/fintech-design-ux.webp"
    ],
    lastReviewed: "2021/01/16",
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    image:
      "https://adamfard.com/static/2742500823d429dbc7a8106e6664e1af/58556/fintech-design-ux.webp",
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description:
      "We’re looking for a flexible and versatile marketer who will be responsible for the growth of our inbound and outbound sales channels. Marketing manager responsibilities include tracking and analyzing the performance of advertising campaigns, managing the marketing budget and ensuring that all marketing material is in line with our brand identity.",
    disambiguatingDescription:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    mainEntityOfPage: "https://adamfard.com/marketing-manager",
    image:
      "https://adamfard.com/static/2bf104f6b91a675fc622caf7f9bd6b77/b2c2d/edtech-design-ux.webp",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://adamfard.com/marketing-manager",
          name: "Marketing Specialist"
        }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(mainjson)}</script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};
